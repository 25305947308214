<div class="header-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="header-widget">
                    <ul>
                        <li><i class="icofont-clock-time"></i> Sun - Thu : 10:00AM - 09:00PM</li>
                        <li><a style="color: white;" href="https://goo.gl/maps/fhfPJwTPN1LJ26jQ6" target="_blank"><i class="icofont-location-pin"></i> Prakash Nagar round park, Rajahmundry</a></li>
                        <li><i class="icofont-phone"></i> <a href="tel:0883-2475253">0883-2475253</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4">
                <div class="header-social text-end">
                    <!-- <ul>
                        <li><a target="_blank" href="#"><i class="icofont-facebook"></i></a></li>
                        <li><a target="_blank" href="#"><i class="icofont-twitter"></i></a></li>                 
                        <li><a target="_blank" href="#"><i class="icofont-instagram"></i></a></li>
                    </ul> -->
                </div>
            </div>              
        </div>
    </div>
</div>
<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo.png" alt="logo"> </a>
    </div>
    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" href="index.html">
                    <img src="assets/img/logo.png" alt="logo"> </a>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto">                 
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a routerLink="/srisanthi/hospital/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>
                        <li class="nav-item"><a routerLink="/srisanthi/hospital/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                        <li class="nav-item"><a routerLink="/srisanthi/hospital/our-branches" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Our-Branches</a></li>
                        <li class="nav-item"><a routerLink="/srisanthi/hospital/portfolio" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio</a></li>
                        <li class="nav-item"><a routerLink="/srisanthi/hospital/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>
                     <div class="navbar-button">
                        <!-- <a routerLink="/srisanthi/hospital/contact">Get More Information</a> -->
                    </div> 
                </div>
            </nav>
        </div>
    </div>
</div>