import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DEFAULT_ROUTES } from './routes/default-layout-routes';
import { BACKEND_LAYOUT } from './routes/backend-layout-routes';
import { BackendLayoutComponent } from './layouts/backend-layout/backend-layout.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
const routes: Routes = [  
    { path: '', component: DefaultLayoutComponent, children: DEFAULT_ROUTES },
    { path: 'srisanthi', component: BackendLayoutComponent, children: BACKEND_LAYOUT }
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }