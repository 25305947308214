<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" alt="logo">
                        </a>
                    </div>
                    <p class="justy">To strive hard to provide clinically excellent,high quality patient-centric health
                        care at global standards by cutting edge technology to outshine in fulfilling social
                        responsibilities.</p>
                    <div class="email">
                        <!-- <form class="newsletter-form">
                            <input type="mail" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                            <button class="default-btn electronics-btn" type="submit"><i class="icofont-location-arrow"></i></button>
                        </form> -->
                        <p>
                            <i class="icofont-ui-message"></i> <a style="color: white;"
                                href="mailto:srisanthienthospitals@gmail.com">
                                &nbsp;&nbsp;srisanthienthospital@gmail.com</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Find Us</h3>
                    <p class="find-text">Rajahmundry</p>
                    <ul class="find-us">
                        <li>
                            <a href="https://goo.gl/maps/fhfPJwTPN1LJ26jQ6" target="_blank"> <i
                                    class="icofont-location-pin"></i> Address: Prakash Nagar round park, Vinayaka temple
                                opp street, Rajahmundry
                                East Godavari District
                                Pin:533103
                            </a>
                        </li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:+0883-2475253">0883-2475253</a>
                        </li>
                        <!-- <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:+0883-2475253">0883-2475253</a>
                        </li> -->

                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Find Us</h3>
                    <p class="find-text">PALAKOLLU</p>
                    <ul class="find-us">
                        <li>
                            <a href="https://goo.gl/maps/RaXE1nUsAirGXKvT8" target="_blank"> <i
                                    class="icofont-location-pin"></i>Address: Opposite Head Post Office Palakollu
                            </a>
                        </li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:08814-220066">08814-220066</a>
                        </li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel: 9393955253">9393955253</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Find Us</h3>
                    <p class="find-text">TANUKU</p>
                    <ul class="find-us">
                        <li>
                            <a href="https://goo.gl/maps/jJpnRA8Emkt8dG9j6" target="_blank"> <i
                                    class="icofont-location-pin"></i> Address: Narendra Diabetic Center Building
                                Opposite Boy's High School
                                Valluri Street,
                                Tanuku
                                West Godavari District
                                Pin:534211</a>
                        </li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:9494625253"> 9494625253</a>
                        </li>
                        <!-- <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:9494625253">9494625253</a>
                        </li>                      -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-footer">
        <div class="container">
            <div class="row  align-items-center">
                <div class="col-lg-4">
                    <div class="footer-social">
                        <ul>
                            <li><a href="https://www.facebook.com/people/SriSanthi-ENT" target="_blank"><i
                                        class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5" style="text-align: center;">
                    <div>
                        <p>© SriSanthi Hospitals is Developed by <a href="https://amaravathisoftware.com/"
                                target="_blank">Amaravathi Software</a></p>
                    </div>
                </div>
                <div class="col-lg-3" style="text-align: center;">
                    <img src="assets/img/insurance_services/14.png" style="height:70px;">
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="top-btn">
    <i class="icofont-scroll-long-up"></i>
</div>